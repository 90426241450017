import fb from "firebase"
import "firebase/auth"
import "firebase/firestore"
import { CollectionUtils } from "./CollectionUtils"

const firebaseConfig = {
  apiKey: "AIzaSyA7ix5hL4yrSKwGg1kzF0MvEcTdt1V4DbY",
  authDomain: "bkkc-open.firebaseapp.com",
  projectId: "bkkc-open",
  storageBucket: "bkkc-open.appspot.com",
  messagingSenderId: "887180765189",
  appId: "1:887180765189:web:c1a65002fbf8b7d516d46c",
  measurementId: "G-3GX48Y6G9L"
};

fb.initializeApp(firebaseConfig)

export const firebase = fb
export const auth = firebase.auth()
export const firestore = firebase.firestore()

export const GameCollection = new CollectionUtils(firestore, "game")

export const ADMIN_USER_ID = "790U4jJyISXJgcuc1YY65hnlHtq2"
