import { useEffect, useState } from "react"
import { ADMIN_USER_ID, auth, firebase } from "../../firebase"
import { Login } from "./Login"
import { Games } from "./Games"
import { Scores } from "./Scores"

export const Admin = () => {
	const [isAdmin, setAdmin] = useState(false)
	const [gameID, setGameID] = useState(null)

	useEffect(() => {
		auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)

		return auth.onAuthStateChanged((user) => {
			setAdmin(user && user.uid === ADMIN_USER_ID)
		})
	}, [])

	return !isAdmin ? <Login /> : !gameID ? <Games setGameID={setGameID} /> : <Scores gameID={gameID} unsetGame={() => setGameID(null)} />
}
