import React from "react"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import { Admin, Scores } from "./screens"

//import styles from './App.module.scss';

const App = () => {
	return (
		<Router>
			<Switch>
				<Route path="/admin">
					<Admin />
				</Route>

				<Route path="/scores">
					<Scores />
				</Route>

				<Route path="/">
					<Scores />
				</Route>
			</Switch>
		</Router>
	)
}

export default App
