export const calculateTotal = (player) => {
	return (parseInt(player.r1) || 0) + (parseInt(player.r2) || 0) + (parseInt(player.r3) || 0)
}

export const comparePlayersByName = (a, b) => {
	return a.name.localeCompare(b.name)
}

export const comparePlayersByPoints = (a, b) => {
	return compareScores(a.total ?? calculateTotal(a), b.total ?? calculateTotal(b)) || comparePlayersByName(a, b)
}

const SORT_ORDER_ASC = true

export const compareScores = (a, b) => {
	let cmp = a < b ? -1 : a > b ? 1 : 0

	if (!SORT_ORDER_ASC) {
		cmp = -cmp
	}

	return cmp
}
