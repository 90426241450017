import { useEffect, useState } from "react"
import cn from "classnames"
import { GameCollection } from "../../firebase"
import { calculateTotal, comparePlayersByPoints, compareScores } from "../../utils"
import { Link } from "react-router-dom"

import styles from "./Scores.module.scss"

export const Scores = () => {
	const [game, setGame] = useState(null)
	const [error, setError] = useState(null)

	useEffect(() => {
		return GameCollection.collection
			.orderBy("createdAt", "desc")
			.limit(1)
			.onSnapshot((snapshot) => {
				if (snapshot.empty) {
					setError("No game found.")
					setGame(null)
				} else {
					const game = GameCollection.docToObject(snapshot.docs[0])

					game.players.forEach((player) => {
						player.total = calculateTotal(player)
					})

					game.players.sort(comparePlayersByPoints)

					let pos = 0
					let lastScore = 0
					let tied = 0

					game.players.forEach((player) => {
						if (pos === 0 || compareScores(lastScore, player.total) === -1) {
							pos += tied + 1
							lastScore = player.total
							tied = 0
						} else {
							tied++
							player.tied = true
						}

						player.pos = pos
					})

					game.activeRound = [1, 2, 3].find((round) => game.players.some((player) => player[`r${round}`] === ""))

					if (!game.activeRound) {
						game.winners = game.players
							.filter((player) => player.pos === 1)
							.map((player) => player.name)
							.join(", ")
					}

					game.hasScores = Boolean(game.players.length && (game.activeRound > 1 || !game.activeRound))

					setError(null)
					setGame(game)
				}
			})
	}, [])

	const GetFormattedScore = score => {
		return score < 0 ? score : score > 0 ? `+${score}` : score === '0' ? 'E' : '';
	}

	return (
		<section className="section">
			<div className="container">
				<div className={styles.scoreboard}>
					<div className={styles.scoreboardHashtag}></div>
					<header className={styles.scoreboardHeader}>
						<div className={styles.scoreboardLogo}></div>
						<div className={styles.scoreboardTitle}>The BKKC Open Leaderboard</div>
					</header>
					{!game && !error ? (
						<>Loading...</>
					) : error ? (
						<article className="message is-danger">
							<div className="message-body">{JSON.stringify(error)}</div>
						</article>
					) : (
						<table className={cn("table", "is-fullwidth", styles.scoreboardTable)}>
							<thead>
								<tr>
									<th>{game.activeRound ? <>Round {game.activeRound} in progress</> : game.players.length ? <>{game.winners} won!</> : <></>}</th>
									{game.hasScores && <th>Total</th>}
									<th>Round 1</th>
									<th>Round 2</th>
									<th>Round 3</th>
								</tr>
							</thead>

							<tbody>
								{game.players.map((player) => (
									<tr>
										<td>
											{game.hasScores && <span className={styles.place}>{!player.tied && player.pos}</span>}
											{player.name}
										</td>
										{game.hasScores && <td className={styles.total}>{player.total}</td>}
										<td>{GetFormattedScore(player.r1)}</td>
										<td>{GetFormattedScore(player.r2)}</td>
										<td>{GetFormattedScore(player.r3)}</td>
									</tr>
								))}

								{!game.players.length && (
									<tr>
										<td colSpan={4}>No players in game yet.</td>
									</tr>
								)}
							</tbody>
						</table>
					)}
				</div>
			</div>

			<p class="has-text-centered">
				<Link to="/admin">19th Hole</Link>
			</p>
		</section>
	)
}
