export class CollectionUtils {
	constructor(firestore, collection) {
		this.firestore = firestore
		this.collection = firestore.collection(collection)
	}

	docToObject(doc) {
		return {
			...doc.data(),
			ID: doc.id,
		}
	}

	listen(ID, callback) {
		return this.collection.doc(ID).onSnapshot((doc) => callback(this.docToObject(doc)))
	}

	getByID(ID) {
		return this.collection
			.doc(ID)
			.get()
			.then((doc) => {
				return doc.exists ? this.docToObject(doc) : null
			})
	}

	create(data) {
		return this.collection
			.add(data)
			.then((docRef) => docRef.get())
			.then((doc) => this.docToObject(doc))
	}

	set(data) {
		const { ID, ...dataWithouID } = data

		return this.collection.doc(ID).set(dataWithouID)
	}

	update(data) {
		const { ID, ...dataWithouID } = data

		return this.collection.doc(ID).update(dataWithouID)
	}

	delete(ID) {
		return this.collection.doc(ID).delete()
	}
}
