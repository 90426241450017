import { auth } from "../../firebase"

export const Logout = ({ children }) => {
	const handleLogout = (event) => {
		event.preventDefault()

		auth.signOut()
	}

	return (
		<div className="is-flex is-justify-content-space-between mb-4">
			<div>{children}</div>

			<button className="button is-link" onClick={handleLogout}>
				Log out
			</button>
		</div>
	)
}
