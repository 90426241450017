import cn from "classnames"
import { useEffect, useState } from "react"
import { Logout } from "./Logout"
import { GameCollection } from "../../firebase"
import { calculateTotal, comparePlayersByName } from "../../utils"

import styles from "./Scores.module.scss"

export const Scores = ({ gameID, unsetGame }) => {
	const [game, setGame] = useState(null)
	const [error, setError] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [submitError, setSubmitError] = useState(null)

	useEffect(() => {
		GameCollection.getByID(gameID)
			.then((game) => {
				// Sort players like the leaderboard before showing in admin
				game.players.sort(comparePlayersByName)

				setGame(game)
			})
			.catch((error) => setError(error))
	}, [gameID])

	const handleBack = (event) => {
		event.preventDefault()

		unsetGame()
	}

	const handleAddPlayer = (event) => {
		event.preventDefault()

		game.players.push({
			name: "",
			discord: "",
			twitch: "",
			r1: "",
			r2: "",
			r3: "",
		})

		setGame({ ...game })
	}

	const handlePlayerChange = (event, player, field) => {
		player[field] = event.target.value
		setGame({ ...game })
	}

	const handleDeletePlayer = (event, index) => {
		event.preventDefault()

		game.players.splice(index, 1)

		setGame({ ...game })
	}

	const handleSave = (event) => {
		event.preventDefault()

		if (submitting) {
			return
		}

		setSubmitting(true)
		setSubmitError(null)

		// Fix all player scores to be proper numbers
		game.players.forEach((player) => {
			if (player.r1 !== "") {
				player.r1 = "" + (parseInt(player.r1) || 0)
			}
			if (player.r2 !== "") {
				player.r2 = "" + (parseInt(player.r2) || 0)
			}
			if (player.r3 !== "") {
				player.r3 = "" + (parseInt(player.r3) || 0)
			}
		})

		GameCollection.update(game)
			.catch((error) => setSubmitError(error))
			.then(() => setSubmitting(false))
	}

	/*
        TODO:
        - Allow naming of game?
    */

	return (
		<section className="section">
			<div className="container">
				<Logout>
					<button className="button is-link" onClick={handleBack}>
						Back
					</button>
				</Logout>

				{!game && !error ? (
					<>Loading...</>
				) : error ? (
					<article className="message is-danger">
						<div className="message-body">{JSON.stringify(error)}</div>
					</article>
				) : (
					<>
						{submitError && (
							<article className="message is-danger">
								<div className="message-body">{JSON.stringify(submitError)}</div>
							</article>
						)}

						<div className={styles.scoreboard}>
							<div className={styles.scoreboardHashtag}></div>
							<header className={styles.scoreboardHeader}>
								<div className={styles.scoreboardLogo}></div>
								<div className={styles.scoreboardTitle}>The BKKC Open Leaderboard</div>
							</header>
							<table className={cn("table", "is-fullwidth", styles.scoreboardTable)}>
								<thead>
									<tr>
										<th colSpan={3}>Player</th>
										<th>Round 1</th>
										<th>Round 2</th>
										<th>Round 3</th>
										<th>Total</th>
										<th></th>
									</tr>
								</thead>

								<tbody>
									{game.players.map((player, i) => (
										<tr>
											<td>
												<div className="control">
													<input
														className="input"
														type="text"
														placeholder="Name"
														value={player.name}
														tabIndex={i * 3 + 1}
														onChange={(event) => handlePlayerChange(event, player, "name")}
													/>
												</div>
											</td>
											<td>
												<div className="control">
													<input
														className="input"
														type="text"
														placeholder="Discord"
														value={player.discord}
														tabIndex={i * 3 + 2}
														onChange={(event) => handlePlayerChange(event, player, "discord")}
													/>
												</div>
											</td>
											<td>
												<div className="control">
													<input
														className="input"
														type="text"
														placeholder="Twitch"
														value={player.twitch}
														tabIndex={i * 3 + 3}
														onChange={(event) => handlePlayerChange(event, player, "twitch")}
													/>
												</div>
											</td>
											<td>
												<div className="control">
													<input
														className="input"
														type="text"
														pattern="-?\d+"
														value={player.r1}
														tabIndex={game.players.length * 3 + 1}
														onChange={(event) => handlePlayerChange(event, player, "r1")}
													/>
												</div>
											</td>
											<td>
												<div className="control">
													<input
														className="input"
														type="text"
														pattern="-?\d+"
														value={player.r2}
														tabIndex={game.players.length * 4 + 1}
														onChange={(event) => handlePlayerChange(event, player, "r2")}
													/>
												</div>
											</td>
											<td>
												<div className="control">
													<input
														className="input"
														type="text"
														pattern="-?\d+"
														value={player.r3}
														tabIndex={game.players.length * 5 + 1}
														onChange={(event) => handlePlayerChange(event, player, "r3")}
													/>
												</div>
											</td>
											<td className={styles.total}>{calculateTotal(player)}</td>
											<td>
												{/* eslint-disable-next-line */}
												<a onClick={(event) => handleDeletePlayer(event, i)}>x</a>
											</td>
										</tr>
									))}
								</tbody>
							</table>

							<div className="is-flex is-justify-content-space-between">
								<button className="button is-link" onClick={handleAddPlayer}>
									Add Player
								</button>
								<button className={cn("button is-primary", { "is-loading": submitting })} onClick={handleSave}>
									Save Changes
								</button>
							</div>
						</div>
					</>
				)}
			</div>
		</section>
	)
}
