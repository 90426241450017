import { useState } from "react"
import cn from "classnames"
import { auth } from "../../firebase"

export const Login = () => {
	const [email, setEmail] = useState("")
	const [password, setPassword] = useState("")
	const [submitting, setSubmitting] = useState(false)
	const [error, setError] = useState(null)

	const handleSubmit = (event) => {
		event.preventDefault()

		if (submitting) {
			return
		}

		setSubmitting(true)

		if (auth.currentUser) {
			auth.signOut().then(signIn)
		} else {
			signIn()
		}
	}

	const signIn = () => {
		auth.signInWithEmailAndPassword(email, password)
			.catch((error) => setError(error))
			.then(() => setSubmitting(false))
	}

	return (
		<section className="section">
			<div className="container">
				<div className="columns">
					<div className="column is-half is-offset-one-quarter">
						<h1 className="is-size-3">Admin Login</h1>

						{error && (
							<article className="message is-danger">
								<div className="message-body">{JSON.stringify(error)}</div>
							</article>
						)}

						<form onSubmit={handleSubmit}>
							<div className="field">
								<div className="control">
									<input className="input" type="email" name="email" placeholder="Email address" value={email} onChange={(event) => setEmail(event.target.value)} />
								</div>
							</div>

							<div className="field">
								<div className="control">
									<input className="input" type="password" name="password" placeholder="Password" value={password} onChange={(event) => setPassword(event.target.value)} />
								</div>
							</div>

							<button className={cn("button is-primary", { "is-loading": submitting })} type="submit">
								Submit
							</button>
						</form>
					</div>
				</div>
			</div>
		</section>
	)
}
