import { useEffect, useState } from "react"
import cn from "classnames"
import { Logout } from "./Logout"
import { GameCollection } from "../../firebase"

export const Games = ({ setGameID }) => {
	const [games, setGames] = useState(null)

	const [isCreatingGame, setCreatingGame] = useState(false)
	const [errorCreatingGame, setErrorCreatingGame] = useState(null)

	useEffect(() => {
		return GameCollection.collection.orderBy("createdAt", "desc").onSnapshot((snapshot) => setGames(snapshot.docs.map((doc) => GameCollection.docToObject(doc))))
	}, [])

	const handleNewGame = (event) => {
		event.preventDefault()

		if (isCreatingGame) {
			return
		}

		setCreatingGame(true)
		setErrorCreatingGame(null)

		GameCollection.create({
			players: [],
			createdAt: +new Date(),
			updatedAt: +new Date(),
		})
			.then((game) => setGameID(game.ID))
			.catch((error) => {
				setCreatingGame(false)
				setErrorCreatingGame(error)
			})
	}

	const handleDeleteGame = (event, gameID) => {
		event.preventDefault()

		GameCollection.delete(gameID)
	}

	/*
        TODO:
        - Allow naming of game when creating new?
		- Set games as active/inactive for easier way of picking which game to show leaderboard for public?
    */

	return (
		<section className="section">
			<div className="container">
				<Logout />

				{!games ? (
					<>Loading...</>
				) : (
					<>
						<table className="table">
							<thead>
								<tr>
									<th>Game ID</th>
									<th>Created</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								{games.map((game) => (
									<tr key={game.ID}>
										<td>
											{/* eslint-disable-next-line */}
											<a onClick={() => setGameID(game.ID)}>{game.ID}</a>
										</td>
										<td>{game.createdAt}</td>
										<td>
											{/* eslint-disable-next-line */}
											<a onClick={(event) => handleDeleteGame(event, game.ID)}>x</a>
										</td>
									</tr>
								))}

								{!games.length && (
									<tr>
										<td colSpan={3}>No games.</td>
									</tr>
								)}
							</tbody>
						</table>

						{errorCreatingGame && (
							<article className="message is-danger">
								<div className="message-body">{JSON.stringify(errorCreatingGame)}</div>
							</article>
						)}

						<button className={cn("button is-primary", { "is-loading": isCreatingGame })} onClick={handleNewGame}>
							New Game
						</button>
					</>
				)}
			</div>
		</section>
	)
}
